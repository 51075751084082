import React from "react";
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Toaster = props => {
  const { open, onClose, severity, message} = props

  const handleClose = (event, reason) => {
    onClose(event, reason)
  }

  return (
    <Snackbar 
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert 
        severity={severity}
        onClose={handleClose}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}

export default Toaster