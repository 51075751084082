import React from "react";
import { Route, Switch } from 'react-router-dom'

import { Header, PageContent, Footer } from '../layout'
import Draw from '../../modules/draw/draw'

import appRoutes from '../Router/appRoutes'

function AppLayout () {

  return (
    <div className="container-fluid">
      <div className="row justify-content-center">
        <Header />
        <Switch>
          <React.Fragment>
            <div className="container-fluid">
              <PageContent>
                <Route exact path="/" component={Draw} />
                {appRoutes.map((route, index) => {
                  return <Route key={index} exact path={route.path} component={route.component} />
                })}
                <Footer />
              </PageContent>
            </div>
          </React.Fragment>
        </Switch>
      </div>
    </div>
  )
}

export default AppLayout