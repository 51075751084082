import React from 'react'
import { useTranslation } from 'react-i18next'
import { withStyles, makeStyles, Typography, Tooltip } from '@material-ui/core'
import { useDispatch, useStore } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import skin from './img/skin.png'
import hair from './img/hair.png'
import mouth from './img/beard.png'
import addon from './img/addon.png'
import wear from './img/wear.png'
import arrowLeft from './img/arrow_left.png'
import arrowRight from './img/arrow_right.png'
import male from './img/male.png'
import female from './img/female.png'
import * as Json from '../../consume'

const useStyles = makeStyles(theme => ({
  forms: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 100,
    [theme.breakpoints.down('xs')]: {
      top: 10
    }
  },
  formControl: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 410,
    marginTop: 50,
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      marginTop: 55,
      '&.right': { right:0 }
    }
  },
  textures: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5
  },
  selection: {
    display: 'flex',
    flexFlow: 'row wrap',
    maxWidth: 305,
    margin: 'auto',
    '& .labels': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '& .label': {width : '50%'}
    }
  },
  selectionControl: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 410,
  },
  button: {
    background: 'transparent',
    border: 'transparent',
    cursor: 'pointer',
    outline: 'none',
    '&.small': {
      padding: 0,
      '& img': {opacity: '0.85'},
      '&.active, &:hover img': {
        filter: 'brightness(120%)',
        transform: 'scale(1.1)',
        opacity: 1,
      },
      '&.active:hover img': {
        filter: 'brightness(100%)',
        transform: 'scale(1)',
      }
    }
  },
  form: {
    '& img': {opacity: '0.85'},
    '&:hover img': {
      filter: 'brightness(150%)',
      transform: 'scale(1.2)',
      opacity: 1,
    }
  },
  texture: {
    '& img': {opacity: '0.85'},
    '&.active, &:hover img': {
      filter: 'brightness(150%)',
      transform: 'scale(1.2)',
      opacity: 1,
    },
    '&.active:hover img': {
      filter: 'brightness(100%)',
      transform: 'scale(1)',
    }
  },
  colorEfx: {
    '&.active img, &:hover img': {
      filter: 'invert(48%) sepia(13%) saturate(3207%) hue-rotate(100deg) brightness(95%) contrast(80%) !important',
    }
  },
  selectionText: {
    color: '#54a936',
    fontWeight: '800',
    textAlign: 'center',
    marginTop: 10
  },
  message: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    fontSize: 16,
    color: '#333',
    marginBottom: 20
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: '0 auto 20px'
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  colorBox: {
    display: 'flex',
    padding: '5px 10px',
    justifyContent: 'center',
    margin: 'auto',
    maxWidth: 305,
    flexFlow: 'row wrap',
    '& .label': {width: '100%', marginTop: -10},
    '& button': {
      cursor: 'pointer',
      width: 30,
      height: 30,
      margin: 5,
      background: 'transparent',
      border: '3px solid hsla(230,8%,60%,.5)',
      borderRadius: 2
    }
  }
}))

const TxTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: 14,
  },
}))(Tooltip);

const SKIN = 'skin'
const HAIR = 'hair'
const WEAR = 'wear'
const ADDON = 'addon'
const MOUTH = 'mouth'

const layerPeep = {
  skin: '1gen',
  wear: '2wear',
  hair: '3hair',
  mouth: '4mouth',
  addon: '5addon'
}

export const ControlForm = props => {
  const css = useStyles()
  const classes = classnames
  const store = useStore()
  const dispatch = useDispatch()

  const gender = store.getState().draw.gender
  const looking = store.getState().draw.looking

  return <div className={css.forms}>
    <div className={css.formControl}>
      <button className={classes(css.button, 'small', !gender ? 'active' : '')}
        onClick={e => dispatch({type: 'SET_GENDER', payload: 0})}>
        <img src={male} alt="male"/>
      </button>
      <button className={classes(css.button, 'small', gender ? 'active' : '')}
        onClick={e => dispatch({type: 'SET_GENDER', payload: 1})}>
        <img src={female} alt="female"/>
      </button>
    </div>
    <div className={classes(css.formControl, 'right')}>
      <TxTooltip title="direction left" arrow>
        <button className={classes(css.button, css.texture, css.colorEfx, 'small', looking ? 'active' : '')}
          onClick={e => dispatch({type: 'SET_LOOK', payload: true})}>
          <img src={arrowLeft} alt="left"/>
        </button>
      </TxTooltip>
      <TxTooltip title="direction right" arrow>
        <button className={classes(css.button, css.texture, css.colorEfx, 'small', !looking ? 'active' : '')}
          onClick={e => dispatch({type: 'SET_LOOK', payload: false})}>
          <img src={arrowRight} alt="right"/>
        </button>
      </TxTooltip>
    </div>
  </div>
}

export const ControlTextures = props => {
  const css = useStyles()
  const classes = classnames
  const {layer, setLayer} = props

  return <div className={css.textures}>
    <button onClick={event => setLayer(SKIN)}
      className={classes(css.button, css.texture, layer === SKIN ? 'active' : '')}>
      <img src={skin} alt="Skin"/>
    </button>
    <button onClick={event => setLayer(HAIR)}
      className={classes(css.button, css.texture, layer === HAIR ? 'active' : '')}>
      <img src={hair} alt="Hair"/>
    </button>
    <button onClick={event => setLayer(MOUTH)}
      className={classes(css.button, css.texture, layer === MOUTH ? 'active' : '')}>
      <img src={mouth} alt="Mouth"/>
    </button>
    <button onClick={event => setLayer(WEAR)}
      className={classes(css.button, css.texture, layer === WEAR ? 'active' : '')}>
      <img src={wear} alt="Wear"/>
    </button>
    <button onClick={event => setLayer(ADDON)}
      className={classes(css.button, css.texture, layer === ADDON ? 'active' : '')}>
      <img src={addon} alt="Addon"/>
    </button>
  </div>
}

export const ControlSelection = props => {
  const css = useStyles()
  const dispatch = useDispatch()
  const store = useStore()
  const classes = classnames
  const {t} = useTranslation('common')
  const {layer, variant, skins} = props

  const gender = store.getState().draw.gender
  const index = store.getState().draw[layer]

  const onIndexChange = (prop, value) => {
    let newIndex = index[prop] + value
    if (prop === 'variant') {
      dispatch({type: 'SET_INDEX', payload: {
        name: layer, index: prop, data: validIndex(variant, newIndex)
      }})
      dispatch({type: 'SET_INDEX', payload: {
        name: layer, index: 'index', data: 0
      }})
    } else {
      if (layer === SKIN) {
        dispatch({type: 'SET_INDEX', payload: {
          name: layer, index: prop, data: validIndex(skins, newIndex)
        }})
      }
      else {
        let array = variant ? Json[variant[index.variant]][gender] : Json[layer][gender]
        newIndex = validIndex(array, newIndex)
        dispatch({type: 'SET_INDEX', payload: {
          name: layer, index: prop, data: validIndex(array, newIndex)
        }})
      }
    }
  }

  const validIndex = (array, index) => {
    if (index < 0) return array.length - 1
    else if (index > array.length - 1) return 0
    else return index
  }

  return (
  <div className={classes(css.selection)}>
    <div className="labels">
      <div className="label">
        <Typography className={css.selectionText}>
          {layer}
        </Typography>
      </div>
      {variant && <div className="label">
        <Typography className={css.selectionText}>
          {t('variant:'+variant[index.variant])}
        </Typography>
      </div>}
    </div>
    {variant && 
    <div className={css.selectionControl}>
      <TxTooltip title="previous variation" arrow>
        <button className={classes(css.button, css.texture, css.colorEfx, 'small')}
          onClick={e => onIndexChange('variant', -1)}>
          <img src={arrowLeft} alt="left"/>
        </button>
      </TxTooltip>
      <TxTooltip title="next variation" arrow>
        <button className={classes(css.button, css.texture, css.colorEfx, 'small')}
          onClick={e => onIndexChange('variant', 1)}>
          <img src={arrowRight} alt="right"/>
        </button>
      </TxTooltip>
    </div>}
    <div className={css.selectionControl}>
      <TxTooltip title={`previous ${layer}`} arrow>
        <button className={classes(css.button, css.texture, css.colorEfx, 'small')}
          onClick={e => onIndexChange('index', -1)}>
          <img src={arrowLeft} alt="left"/>
        </button>
      </TxTooltip>
      <TxTooltip title={`next ${layer}`} arrow>
        <button className={classes(css.button, css.texture, css.colorEfx, 'small')}
          onClick={e => onIndexChange('index', 1)}>
          <img src={arrowRight} alt="right"/>
        </button>
      </TxTooltip>
    </div>
  </div>)
}

export const ColorSelection = props => {
  const css = useStyles()
  const store = useStore()
  const classes = classnames
  const {t} = useTranslation('common')
  const {layer, setColor, setIndex, setOpen} = props

  const peep = store.getState().draw.peeps[layerPeep[layer]]

  const handleClick = (color, index) => {
    setIndex(index)
    setColor(color)
    setOpen(true)
  }

  return <div className={classes(css.colorBox)}>
    <div class="label">
      <Typography className={css.selectionText}>
        {t('color:color_palette')}
      </Typography>
    </div>
    {peep.map((p, i) => 
      <button key={i} style={{background: p[0]}} onClick={() => handleClick(p[0], i)}></button>)}
  </div>
}

ControlSelection.propTypes = {
  layer: PropTypes.string.isRequired,
  variant: PropTypes.array,
  skins: PropTypes.array
}

ControlTextures.propTypes = {
  layer: PropTypes.string.isRequired,
  setLayer: PropTypes.func
}

ColorSelection.propTypes = {
  layer: PropTypes.string.isRequired,
  setColor: PropTypes.func,
  setIndex: PropTypes.func,
  setOpen: PropTypes.func
}
