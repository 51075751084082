import React from "react"
import clsx from 'clsx';
//import { useTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { compose } from 'redux'
import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {FollowTwitter} from './Follow'

import Drawer from './Drawer'

//import logo from '../img/logo.png'
import isotype from '../img/8bitpix_400.png'

import './layout.css'

const useStyles = makeStyles(theme => ({
  grow: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: '#164322'
  },
  menuWrapper: {
    '& .MuiButtonBase-root': {
      backgroundColor: '#fff',
      borderRadius: '4px 0px 0px 4px',
    },
    '& img': {
      width: '36px',
      borderRadius: '0px 4px 4px 0px',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: '#fff'
  },
  hide: {
    display: 'none',
  }
}))

const Header = props => {
  const classes = useStyles();
  //const { t } = useTranslation(['common']);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true)
  };

  return (<>
    <AppBar className={`p-3 ${classes.appBar}`} position="static" color="default">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={clsx(classes.menuButton)}
        >
          <MenuIcon />
        </IconButton>
        <div className={classes.grow} />
        <div className="page-logo">
          <img src={isotype} alt="" />
        </div>
        <div className={classes.grow} />
        <div edge="end" className="row">
          <FollowTwitter />
        </div>
      </Toolbar>
    </AppBar>
    <Drawer open={open} setOpen={setOpen}/>
  </>
  );
}

const mapStateToProps = (state) => {
  return {
  }
}

const mapDispatchToProps = {

}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Header)