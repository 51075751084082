import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import MailIcon from '@material-ui/icons/Mail';
import InfoIcon from '@material-ui/icons/Info';

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    background: '#262626'
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#262626',
    color: '#fff',
    '& .MuiListItemIcon-root': {
      color: '#fff'
    }
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
}))

const AppDrawer = ({open, setOpen}) => {
  let history = useHistory()
  const theme = useTheme();
  const classes = useStyles()

  const handleHistory = (path) => {
    setOpen(false)
    history.push(path)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  };

  return (
    <>
      <Drawer
        className={classes.drawer}
        variant="temporary"
        anchor="left"
        open={open}
        onEscapeKeyDown={handleDrawerClose}
        onBackdropClick={handleDrawerClose}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
        </div>
        <Divider />
          <List>
            <ListItem button onClick={e => handleHistory('/')}>
              <ListItemIcon><AccountBoxIcon /></ListItemIcon>
              <ListItemText primary={'maker'} />
            </ListItem>
            <ListItem button onClick={e => handleHistory('/about')}>
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText primary={'about'} />
              </ListItem>
          </List>
      </Drawer>
    </>
  );
}

export default AppDrawer