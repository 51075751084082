import { makeStyles } from "@material-ui/core";
import React from "react";

import './layout.css'

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'static',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    padding: '0 5px 5px',
    color: theme.palette.bitpix.main,
    '& a': {
      color: theme.palette.bitpix.main,
    },
    [theme.breakpoints.down('sm')]: {
      width: '80%',
      marginTop: 50
    }
  },
}))

function Footer () {
  const css = useStyles()

  return (
    <footer className={css.footer}>
      ©&nbsp; <a href="http://www.icuantix.com" target="_blank" rel="noopener noreferrer">8bitpix</a>&nbsp;
    - Sergio Bruni © 2020 &nbsp;|&nbsp; All rights reserved
    </footer>
  )
}

export default Footer