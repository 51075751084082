import React, { useRef, useEffect } from 'react'
import { compose } from 'redux'
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core'
import { setCanvas } from '../../modules/draw/actions';

const useStyles = makeStyles(theme => ({
  canvas: {
    //position: 'absolute',
    top: 0,
    left: 0,
    width: 301,
    height: 301,
  }
}))

const Canvas = props => {
  const css = useStyles()
  const canvasRef = useRef(null)
  const {className, context, setCanvas, type, ...rest} = props

  useEffect(() => {
    const canvas = canvasRef.current
    const refContext = canvas.getContext('2d')
    setCanvas({name: refContext.canvas.id, data: canvas})
    return () => {
      setCanvas({name: refContext.canvas.id, data: null})
    }
  }, [context, setCanvas])
  
  return <canvas ref={canvasRef} width="301" height="301"
    {...rest} className={css.canvas+' '+className} />
}

const mapDispatchToProps = {
  setCanvas: setCanvas
}

export default compose(
  connect(null, mapDispatchToProps)
)(Canvas)
