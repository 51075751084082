import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import common from '../common/reducer'
import draw from '../modules/draw/reducer'

const appReducer = combineReducers({
  common,
  draw
});

const rootReducer = (state, action) => {
  if (action.type === 'LOG_OUT')
    state = undefined

  return appReducer(state, action)
}

const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(thunk))
);

export default store;