import React from 'react'
import { makeStyles } from "@material-ui/core/styles";

import followTw from '../img/twitter_follow_grey.png'

const useStyles = makeStyles(theme => ({
  anchor: {
    '& img': {
      width: 40,
      height: 40,
      marginTop: 5
    },
    '&.active, &:hover img': {
      filter: 'brightness(120%)',
      transform: 'scale(1.1)',
      opacity: 1,
    },
  }
}))

export const FollowTwitter = props => {
  const css = useStyles()

  return ( <>
    <a href="https://twitter.com/8bitpixapp?ref_src=twsrc%5Etfw" target="_blank" className={css.anchor} data-show-count="false">
      <img src={followTw} alt="follow twitter" />
    </a>
    <script async src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>
  </>
  )
}