
import Fetch from '../utils/fetch'

export const uploadToImgur = (body) => {
  return new Promise((resolve, reject) => {
    const token = `Client-ID ${process.env.REACT_APP_IMGUR_CLIENT_ID}`
    return Fetch(false, `https://api.imgur.com/3/image`, 'post', body, token, {
      'Content-Type': 'multipart/form-data'
    })
      .then(response => resolve(response))
      .catch(error => reject(error))
  })
}