import {
  SET_CANVAS,
  SET_INDEX,
  SET_MATRIX,
 } from './actions'

const initialState = {
  canvas: null,

  matrix: [],

  gender: 0,
  looking: false,

  peeps: {
    "1gen": [],
    "2wear": [],
    "3hair": [],
    "4mouth": [],
    "5addon": [],
  },

  skin: {index: 0},
  mouth: {index: 0},
  hair: {index: 0, variant: 0},
  wear: {index: 0, variant: 0},
  addon: {index: 0, variant: 0}
}

const drawReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CANVAS:
      return { ...state, ...{[action.payload.name]: action.payload.data} }
    
    case SET_INDEX:
      return {
        ...state,
        ...{[action.payload.name]:{
          ...state[[action.payload.name]],
          [action.payload.index]: action.payload.data
        }}
      }
    
    case "SET_GENDER":
      return {...state, ...{gender: action.payload}}
    
    case "SET_LOOK":
      return {...state, ...{looking: action.payload}}

    case "SET_PEEPS":
      return { ...state, ...{peeps: action.payload}}

    case "SET_PEEP":
      const peeps = {...state.peeps}
      return { 
        ...state,
        ...{peeps: {
          ...peeps,
          ...{[action.payload.name]: action.payload.data}
        }}
      }

    case SET_MATRIX:
      return { ...state, ...{matrix: action.payload}}

    default:
      return state
  }
}

export default drawReducer