import React, {useState, useEffect} from 'react'
import { compose } from 'redux'
import { connect, useDispatch } from "react-redux";
import { makeStyles } from '@material-ui/core'

import {ControlForm, ControlTextures, ControlSelection, ColorSelection} from './controls'
import DialActions from './dialActions'
import ColorPicker from './colorPicker'
import { Canvas } from '../../components/layout'
import { RGBtoHex } from './helpers'
import * as Json from '../../consume'
import { setToaster } from '../../common/actions';
import { postImage } from './actions';

const skins = [
  ["#ECCCA2", "#C6AB88"],
  ["#DAC49E", "#AF9D7E"],
  ["#CAA77C", "#B5966F"],
  ["#AD8B62", "#806748"],
  ["#3C332B", "#302C28"]
]

const variant = {
  hair: ['hair_default', 'hair_hats', 'hair_specials'],
  wear: ['wear_default','wear_brands', 'wear_casuals', 'wear_formals', 'wear_specials', 'wear_sports'],
  addon: ['addon_default', 'addon_flags', 'addon_masks', 'addon_stuffs']
}

const layerPeep = {
  skin: '1gen',
  wear: '2wear',
  hair: '3hair',
  mouth: '4mouth',
  addon: '5addon'
}

const useStyles = makeStyles(theme => ({
  canvas: {
    //position: 'absolute',
    top: 0,
    left: 0,
    width: 301,
    height: 301,
  },
  followBar: {
    marginTop: 315,
    marginBottom: 10,
    display: 'flex',
    justifyContent: 'center',
    padding: '0 10px'
  },
  text: {
    color: '#54a936',
    fontWeight: '800',
    textAlign: 'center',
    border: '1px solid hsla(230,8%,60%,.8)',
    borderRadius: 10,
    margin: '-20px 10px 0',
    whiteSpace: 'pre-line',
    padding: '10px 5px'
  },
}))

const Draw = props => {
  const css = useStyles()
  const width = window.innerWidth
  const dispatch = useDispatch()
  const {
    draw: {
      canvas, peeps, gender, looking,
      skin, hair, wear, mouth, addon
    },
    setToaster, postImage
  } = props
  const [open, setOpen] = useState(false)
  const [context, setContext] = useState(null)

  const [selectedColor, setSelectedColor] = useState('#000000')
  const [assetColorIndex, setAssetColorIndex] = useState(null)
  const [activeTool, setActiveTool] = useState('brush')
  const [activeLayer, setActiveLayer] = useState('skin')

  let _matrix = []
  let flag = false
  let dot_flag = false
  let currX = 0
  let currY = 0

  const clear = () => {
    if (context !== null)
      context.clearRect(0, 0, canvas.width, canvas.height)
  }

  const drawCanvas = (M) => {
    if (!M) {
      Object.keys(peeps).sort().forEach(p => drawCanvas(peeps[p]))
    }
    else if (typeof(M[0]) !== "string") {
      for (let m in M) drawCanvas(M[m]);
    } else {
      for (let m in M[1]) {
        let width = 7, height = 7, Mm;
        if (M[1][m].length) {
          Mm = M[1][m];
          width = Mm[1] * 7;
          if (Mm[2]) height = Mm[2] * 7;

          var l = (looking)? -1 : 1 ;
          for (var x = 0; x < Mm[1]; x++) {
            _matrix[Mm[0] + x*l] = M[0];
            for (var y = 1; y < Mm[2]; y++) {
              _matrix[Mm[0] + x*l + 43 * y] = (activeTool)? undefined : M[0];
            }
          }

          Mm = Mm[0];
        }
        else Mm = M[1][m];
        let posy = Mm/43,
            pyrn = Math.round(posy),
            posx = Mm - (43*pyrn);
        posx = ((posx < 0)? 43 + posx : posx ) * 7;
        posy = (pyrn - ((posy < pyrn)? 1 : 0)) * 7;
        if (looking) {
          posx = canvas.width - posx;
          width *= -1;
        }
        context.fillStyle = M[0] || "#FF0000";
        context.fillRect(posx, posy, width, height);
      }
    }
  }

  const redraw = React.useCallback(() => {
    if (context !== null) {
      clear()
      drawCanvas()
    }
  }, [context, peeps])

  const save = () => {
    let link = document.createElement('a');
    link.download = '8bitpix.png';
    link.href = document.getElementById('canvas').toDataURL('image/png')
    link.click();
  }

  const share = () => {

    const img = document.getElementById('canvas').toDataURL('image/png').split(',')[1]
    const multiPartForm = new FormData()
    multiPartForm.append('image', img)
    //multiPartForm.append('album', process.env.REACT_APP_IMGUR_ALBUM_HASH)
    //multiPartForm.append('type', 'url')
    multiPartForm.append('title', localStorage.getItem('user_code')+'_bitpix')
    multiPartForm.append('description', `Bitpix #pixelart avatar generated with 8bitpix app https://8bitpix.com`)

    postImage(multiPartForm)
    .then(res => {
      if (res.success) {
        const url = `https://twitter.com/intent/tweet?hashtags=pixelart&text=Hey%21%20checkout%20my%20new%20avatar%21%20I%20made%20it%20with%208bitpix%20app%20imgur.com%2F${res.data.id}%20via%20%408bitpixapp`
        window.open(url, '_blank')
      }
    })
    .catch(err => console.log('err', err))
  }

  const drawBrush = (json, ctx) => {
    var M = json;
    var color = M[0];
    
    for (var i in M[1]) {
      var Mc = M[1];

      var width = Mc[i][1] * 7,
          height = Mc[i][2] * 7;

      var posy = Mc[i][0] / 43,
          pyrn = Math.round(posy),
          posx = Mc[i][0] - (43 * pyrn);

      posx = ((posx < 0) ? 43 + posx : posx) * 7;
      posy = (pyrn - ((posy < pyrn) ? 1 : 0)) * 7;

      if ( activeTool === 'brush'){
        ctx.fillStyle = color;
        ctx.fillRect(posx, posy, width, height);
      } 
      else if (activeTool === 'eraser')
        ctx.clearRect(posx, posy, width, height);
    }
  }

  const findXY = (e, res) => {
    return
    let brush = []
    if (res === 'down') {
      currX = e.nativeEvent.offsetX - canvas.offsetLeft;
      currY = e.nativeEvent.offsetY - canvas.offsetTop;

      brush = [selectedColor, []]

      let pos  = parseInt(((currX-7)/7).toString().split(".")[0])
      pos += 43 * (parseInt((currY/7).toString().split(".")[0]))

      brush[1].push([pos,1,1])

      flag = true
      dot_flag = true
      if (dot_flag && activeTool !== 'dropper') {
          drawBrush(brush, context);
          dot_flag = false;
      } else if (dot_flag && activeTool === 'dropper') {
        var imageData, data;
        imageData = context.getImageData(e.nativeEvent.offsetX, e.nativeEvent.offsetY, 1, 1);
        data = imageData.data;
        setSelectedColor('#' + RGBtoHex(data[0],data[1],data[2]))
      }
    }
    if (res === 'up' || res === "out") {
        flag = false;
    }
    if (res === 'move') {
        if (flag) {
          currX = e.nativeEvent.offsetX - canvas.offsetLeft;
          currY = e.nativeEvent.offsetY - canvas.offsetTop;

          brush = [selectedColor, []]

          let pos = parseInt(((currX-7)/7).toString().split(".")[0])
          pos += 43 * (parseInt((currY/7).toString().split(".")[0]))

          brush[1].push([pos,1,1])
          drawBrush(brush, context)
        }
    }
  }

  const validAsset = (asset, index, type = null) => {
    if (type) {
      let part = Json[type][gender][index]
      return part ? part : Json[type][gender][0]
    }
    let part = Json[asset][gender][index]
    return part ? part : Json[asset][gender][0]
  }

  const onContextMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const imageData = context.getImageData(e.nativeEvent.offsetX, e.nativeEvent.offsetY, 1, 1)
    const { data } = imageData
    const color = ('#' + RGBtoHex(data[0],data[1],data[2])).toUpperCase()
    const index = peeps[layerPeep[activeLayer]].findIndex(p => p.includes(color))
    if (index > -1) {
      setSelectedColor(color)
      setAssetColorIndex(index)
      setOpen(true)
    } else {
      setToaster({
        open: true,
        message: 'To change a piece color, it must be selected',
        severity: 'info'
      })
    }
  }

  const onColorChange = (newColor) => {
    setSelectedColor(newColor)
    let newPeep = [...peeps[layerPeep[activeLayer]]]
    newPeep[assetColorIndex][0] = newColor.toUpperCase()
    dispatch({type: 'SET_PEEP', payload: {
      name: [layerPeep[activeLayer]], data: newPeep
    }})
  }

  useEffect(() => {
    if (canvas !== null & context === null) {
      setContext(canvas.getContext('2d'))
    }
  }, [canvas, context])

  useEffect(() => {
    if (context !== null) {
      let genSkin = [
        [skins[skin.index][0], Json.gender[gender][0][1]],
        [skins[skin.index][1], Json.gender[gender][1][1]]
      ]
      console.log('mouth: ', mouth);
      dispatch({type: 'SET_PEEPS', payload: {
        "1gen": genSkin,
        "4mouth": validAsset(null, mouth.index, 'mouth'),
        "3hair": validAsset(variant.hair[hair.variant], hair.index),
        "2wear": validAsset(variant.wear[wear.variant], wear.index),
        "5addon": validAsset(variant.addon[addon.variant], addon.index),
      }})
    }
  }, [gender, looking, context])

  useEffect(() => {
    if (context !== null) {
      let genSkin = [
        [skins[skin.index][0], Json.gender[gender][0][1]],
        [skins[skin.index][1], Json.gender[gender][1][1]]
      ]
      dispatch({type: 'SET_PEEP', payload: {
        name: "1gen", data: genSkin
      }})
    }
  }, [skin])

  useEffect(() => {
    if (context !== null)
      dispatch({type: 'SET_PEEP', payload: {
        name: "4mouth", data: Json.mouth[gender][mouth.index]
      }})
  }, [mouth])

  useEffect(() => {
    if (context !== null)
      dispatch({type: 'SET_PEEP', payload: {
        name: "3hair", data: Json[variant.hair[hair.variant]][gender][hair.index]
      }})
  }, [hair])

  useEffect(() => {
    if (context !== null)
      dispatch({type: 'SET_PEEP', payload: {
        name: "2wear", data: Json[variant.wear[wear.variant]][gender][wear.index]
      }})
  }, [wear])

  useEffect(() => {
    if (context !== null)
      dispatch({type: 'SET_PEEP', payload: {
        name: "5addon", data: Json[variant.addon[addon.variant]][gender][addon.index]
      }})
  }, [addon])

  useEffect(() => {
    if (context !== null) {
      redraw()
    }
  }, [context, peeps, redraw])

  return <div className="container-fluid">
    {width > 310 ? <>
    <div className="container">
      <div className="maker" style={{'position': 'relative', 'zIndex': 100}}
        onMouseMove={event => findXY(event, 'move')}
        onMouseDown={event => findXY(event, 'down')}
        onMouseUp={event => findXY(event, 'up')}
        onMouseOut={event => findXY(event, 'out')}
        onContextMenu={onContextMenu}>
        <Canvas id="canvas" context={canvas} />
      </div>
    </div>
    <ControlForm />
    <ControlTextures layer={activeLayer} setLayer={setActiveLayer} />
    <ControlSelection
      layer={activeLayer} variant={variant[activeLayer]} skins={skins} />
    {peeps && <ColorSelection 
      layer={activeLayer} variant={variant[activeLayer]}
      setColor={setSelectedColor} setIndex={setAssetColorIndex} setOpen={setOpen}/>}
    <DialActions save={save} share={share} />
    <ColorPicker
      open={open}
      color={selectedColor}
      setIndex={setAssetColorIndex}
      onColorChange={onColorChange}
      onClose={e => setOpen(false)} />
      </>:
    <p className={css.text}>
      Unfortunately your device is too small to support 8bitpix.
      <br></br><br></br>
      Try on desktop or using a larger screen.
    </p>}
  </div>
}

const mapStateToProps = (state) => {
  return {
    draw: state.draw
  }
}

const mapDispatchToProps = {
  setToaster: setToaster,
  postImage: postImage
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(Draw)