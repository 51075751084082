import React from "react";
import { connect } from "react-redux";
import { compose } from 'redux'
import { makeStyles } from '@material-ui/core/styles';
import Toaster from './Toaster'

import { setToaster } from '../../common/actions'

const useStyles = makeStyles(theme => ({
  contentPage: {
    padding: '30px 0',
    minHeight: '500px',
    marginBottom: '15px',
    background: 'transparent',
    height: '80vh',
    [theme.breakpoints.down('xs')]: {
      padding: '70px 0 0'
    }
  }
}))

const PageContent = (props, ref) => {
  const classes = useStyles()
  const { toaster, setToaster } = props

  const onToastClose = (event, reason) => {
    if (reason === 'clickaway') return

    setToaster({
      open: false,
      message: '',
    })
  }

  return (
    <div className={classes.contentPage}>
      { props.children }
      <Toaster open={toaster.open} onClose={onToastClose} severity={toaster.severity} message={toaster.message} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    toaster: state.common.toaster
  }
}

const mapDispatchToProps = {
  setToaster: setToaster,
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps)
)(PageContent)