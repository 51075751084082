import { trackPromise } from 'react-promise-tracker';
import * as imgur from '../../services/imgur'

export const SET_CANVAS = "SET_CANVAS"
export const SET_INDEX = "SET_INDEX"
export const SET_MATRIX = "SET_MATRIX"
export const SET_PEEP = "SET_PEEP"

function success(type, payload) {
  return {
      type: type,
      payload: payload
  }
}

export function setCanvas(payload) {
  return { type: SET_CANVAS, payload: payload }
}

export function setIndex(payload) {
  return { type: SET_INDEX, payload: payload }
}

export function postImage(image) {
  return function(dispatch) {
    return trackPromise(imgur.uploadToImgur(image)
      .then(result => {
        dispatch(success('POST_IMG', result))
        return result
      })
    )
  };
}