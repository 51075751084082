import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from "react-redux";

import { AppLayout } from '../layout';
import ReactGA from 'react-ga'



//import appRoutes from './appRoutes'

// const AuthenticatedRoute = ({component: Component, auth: isAuthenticated, ...rest}) => (
//   <Route {...rest} render={(props) => {
//       return isAuthenticated ? 
//         (<Component {...props} />) : 
//         (<Redirect to={{pathname:'/login', state: {from: props.location } }} />)
//     }}
//   />
// )

const history = createBrowserHistory();
const trackingId = 'UA-178407408-1';

ReactGA.initialize(trackingId);

// Initialize google analytics page view tracking
history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});


function Router (props) {

  return (
    <BrowserRouter history={history}>
      <div className="container-fluid">
          <div className="row justify-content-center">
            <Switch>
              <Route exactpath="/" component={AppLayout} />
            </Switch>
          </div>
      </div>
    </BrowserRouter>
  );
}

const mapStateToProps = (state) => {
  return {}
}

export default connect(mapStateToProps, null)(Router)