import React from 'react'

// ROUTER
import Router from './Router/Router'

// REDUX
import { Provider } from "react-redux"
import store from "../store"

// THEME
import { MuiThemeProvider } from "@material-ui/core/styles";
import Theme from '../utils/theme';

// GLOBAL LOADER
import LoadingIndicator from './layout/LoadingIndicator'

// TRANSLATIONS
import '../utils/i18n'

export default function App() {
  console.log('localStorage.getItem(user_code): ', localStorage.getItem('user_code'));
  if (!localStorage.getItem('user_code'))
    localStorage.setItem('user_code', makeId(8))

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={Theme}>
          <Router />
          <LoadingIndicator />
      </MuiThemeProvider>
    </Provider>
  )
}

const makeId = (length) => {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}