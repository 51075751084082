import { createMuiTheme } from '@material-ui/core/styles';

export const primary = '#337AB7'
export const secundary = '#33B7B3'
export const accent = '#EDA05F'
export const error = '#C7403E'
export const warning = '#FFB559'
export const info = '#337AB7'
export const success = '#398256'
export const black = '#000'
export const tuna = '#36383B'
export const silver = '#CCCCCC'
export const white = '#fff'
export const bitpix = '#54a936'

const Theme = createMuiTheme({
    palette: {
      bitpix: {
        main: bitpix,
        contrastText: white,
      },
      primary: {
        main: primary,
        contrastText: white,
        alterText: black
      },
      secondary: {
        main: secundary,
        contrastText: black,
        alterText: white
      },
      accent: {
        main: accent,
        contrastText: black,
        alterText: white
      },
      error: {
        main: error,
        contrastText: white,
      },
      warning: {
        main: warning,
        contrastText: black
      },
      info: {
        main: info,
        contrastText: white
      },
      success: {
        main: success,
        contrastText: white
      },
      muted: {
        main: tuna,
        contrastText: white
      },
      tonalOffset: 0.2
    },
    breakpoints: {
      values: {
        xs: 400,
        sm: 400,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
});

export default Theme