import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { usePromiseTracker } from "react-promise-tracker"
import LoadingOverlay from 'react-loading-overlay'

const spinnerSize = 4
const spinnerPathDotSize = 0.5
const spinnerColSecondary = '#dcdcdc'

const useStyles = makeStyles(theme => ({
  wrapper: {
    '&._loading_overlay_wrapper': {
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '100vh',
      zIndex: -1
    },
    '&._loading_overlay_wrapper--active': {
      zIndex: 2147484000
    }
  },
  spinner: {
    position: 'relative',
    display: 'block',
    fontSize: 10,
    width: spinnerSize * 1+`em`,
    height: spinnerSize * 1+`em`,
    '&:before, &:after': {
      position: 'absolute',
      display: 'block',
      content: '""'
    }
  },
  path: {
    '&:before, &::after': {
      width: (spinnerSize * spinnerPathDotSize * 1)+`em`,
      height: (spinnerSize * spinnerPathDotSize * 1)+`em`,
      borderRadius: 0,
      animationDuration: '900ms',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite'
    },
    '&:before': {
      backgroundColor: theme.palette.bitpix.main,
      transform: `translate3d(0,`+ spinnerSize * (0.5 - spinnerPathDotSize/2) * 1 +`em,0)`,
      animationName: `$pathRightUpAndBack`
    },
    '&:after': {
      backgroundColor: `${spinnerColSecondary}`,
      transform: `translate3d(`+spinnerSize * (1 - spinnerPathDotSize) * 1+`em, `+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`,
      animationName: `$pathLeftDownAndBack`
    }
  },
  '@keyframes pathRightUpAndBack': {
    '0%': {
      transform: `translate3d(0,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`
    },
    '25%': {
      transform: `translate3d(`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`
    },
    '50%': {
      transform: `translate3d(`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0,0)`
    },
    '75%': {
      transform: `translate3d(`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em, `+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em, 0)`
    },
    '100%': {
      transform: `translate3d(0,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em, 0)`
    }
  },
  '@keyframes pathLeftDownAndBack': {
    '0%': {
      transform: `translate3d(`+spinnerSize * (1 - spinnerPathDotSize/2) * 1+`em,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`
    },
    '25%': {
      transform: `translate3d(`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`
    },
    '50%': {
      transform: `translate3d(`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,`+spinnerSize * (1 - spinnerPathDotSize/2) * 1+`em,0)`
    },
    '75%': {
      transform: `translate3d(`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`
    },
    '100%': {
      transform: `translate3d(`+spinnerSize * (1 - spinnerPathDotSize/2) * 1+`em,`+spinnerSize * (0.5 - spinnerPathDotSize/2) * 1+`em,0)`
    }
  }
}));

const BitpixSpinner = props => {
  const css = useStyles()
  return (<div>
    <span className={css.spinner +' '+css.path}></span>
  </div>)
}

const LoadingIndicator = props => {
  const classes = useStyles();
  const { promiseInProgress } = usePromiseTracker();
  
  return (
    <LoadingOverlay
      className={classes.wrapper}
      active={promiseInProgress}
      spinner={<BitpixSpinner />}
      text=''
      >
    </LoadingOverlay>
  )
}

export default LoadingIndicator