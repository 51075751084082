import React from 'react'
import { makeStyles } from '@material-ui/core'
import ReactColorPicker from '@super-effective/react-color-picker';
import CloseIcon from '@material-ui/icons/Close';
import { 
  Dialog, DialogContent, IconButton
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  dialog: {
    position: 'absolute',
    bottom: '10%'
  },
  message: {
    whiteSpace: 'pre-line',
    textAlign: 'center',
    fontSize: 16,
    height: 200,
    width: 200,
    color: '#333',
    marginBottom: 20
  },
  large: {
    width: theme.spacing(9),
    height: theme.spacing(9),
    margin: '0 auto 20px'
  },
  close: {
    position: 'absolute',
    top: 5,
    right: 5
  }
}))

const ColorPicker = ({open, color, setIndex, onColorChange, onClose}) => {
  const classes = useStyles()

  const handleChangeColor = newColor => {
    onColorChange(newColor)
  }

  const handleClose = () => {
    setIndex(null)
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{paper: classes.dialog}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      BackdropProps={{
        invisible: true
      }}>
      <DialogContent>
        <div className={classes.message}>
          <IconButton className={classes.close} aria-label="close" component="span"onClick={handleClose}>
            <CloseIcon />
          </IconButton>
          <ReactColorPicker color={color} onChange={handleChangeColor} />
        </div>
      </DialogContent>
    </Dialog>)
}

export default ColorPicker