import { SET_TOASTER } from './actions'

const initialState = {
  toaster: {
    open: false,
    message: '',
    severity: 'success'
  }
}

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOASTER:
      return { ...state, ...{toaster: action.payload} }

    default:
      return state
  }
}

export default commonReducer