import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import SaveIcon from '@material-ui/icons/Save';
import ShareIcon from '@material-ui/icons/Share';
import ReactGA from 'react-ga'
//import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
  root: {
    right: 10,
    bottom: 10,
    position: 'fixed',
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    '& .MuiFab-primary': {
      background: '#54a936'
    },
    '&.MuiSpeedDialAction-fab': {
      background: 'transparent'
    },
    '&.MuiSpeedDialAction-staticTooltipLabel': {
      background: 'transparent',
      color: '#369aa9',
    },
    '& .MuiSpeedDial-actionsClosed': {
      height: 1
    }
  },
  button: {
    background: 'transparent',
    border: 'transparent',
    cursor: 'pointer',
    padding: 10,
    outline: 'none',
    '&.small': {
      padding: 0,
      '& img': {opacity: '0.85'},
      '&.active, &:hover img': {
        filter: 'brightness(120%)',
        transform: 'scale(1.1)',
        opacity: 1,
      },
      '&.active:hover img': {
        filter: 'brightness(100%)',
        transform: 'scale(1)',
      }
    }
  }
}));


const DialAction = ({save, share}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false)
  
  const actions = [
    { icon: <SaveIcon />, name: 'Save', handle: () => {
      save()
      ReactGA.event({
        category: "Draw",
        action: "User saved bitpix",
      });
      handleClose()
    }},
    { icon: <ShareIcon />, name: 'Share', handle: () => {
      share()
      ReactGA.event({
        category: "Draw",
        action: "User sharer bitpix",
      });
      handleClose()
    }},
    //{ icon: <FavoriteIcon />, name: 'Like', handle: () => handleClose()},
  ];

  const handleSave = () => {
    save()
    handleClose()
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (e) => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        className={classes.speedDial}
        icon={<MoreHorizIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map((action) => (
          <SpeedDialAction
            className={classes.action}
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            tooltipOpen
            onClick={action.handle}
          />
        ))}
      </SpeedDial>
    </div>
  );
}

export default DialAction