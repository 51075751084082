import React from 'react'
import { Divider, makeStyles } from '@material-ui/core'
import logo from '../../components/img/8bitpix_400.png'
import me from './me.png'

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth:800,
    padding: '0 30px 30px',
    margin: 'auto',
    marginTop: -20,
    color: '#fff',
    '& a': {
      color: theme.palette.bitpix.main
    },
    '& img': {
      width: '100%'
    }
  },
  bitpix: {
    width: '50%',
    maxWidth: 200
  }
}))

const About = props => {
  const css = useStyles()

  return (
    <div className={css.root}>
      <img src={logo} alt="8bitpix"/>
      <p>
        8bitpix is an online tool to configure, draw, save and publish pixel art avatars over social media.
        <br></br>Recreated from <a href="https://twitter.com/tuitpix">@tuitpix</a> by <a href="https://twitter.com/sergebruni">@sergebruni</a>.
        <br></br><br></br>
        Advised by <a href="https://twitter.com/sadasant">@sadasant</a>
        <br></br><br></br>
        Hosted in <a href="https://m.do.co/c/cf0ff9cae16a">DigitalOcean</a> by <a href="https://rogs.me/">@rogergonzalez21</a>.
        <br></br><br></br><br></br>
      </p>
      <Divider />
      <p>
        <img src={me} alt="Sergio Bruni" className={css.bitpix}/><br></br><br></br>
        Hey yo!, Sergio Bruni here!. I'm a creative and dedicated designer developer open to new challenges and career opportunities.
        <br></br><br></br>
        Full Stack developer with interest in Open Source Tools, strong orientation in Front-end Development.
        <br></br><br></br><br></br>
      </p>
      <Divider />
      <br></br><br></br>
      <p>
        Licenses
        <br></br><br></br>
        Every programming code in any form available here which does not explicitly says that belongs to another license is under the next copyright:
        <br></br><br></br>
        Copyright © 2020 Sergio Bruni.
      </p>
    </div>
  )
}

export default About