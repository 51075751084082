export default function Fetch(isApi, url, method, body, token = null, headers = {}) {
  console.log('isApi: ', isApi);

  return new Promise((resolve, reject) => {

    // let defaultHeaders = {
    //   'Content-Type': 'application/json',
    //   'Accept': 'application/json',
    //   'timestamp': moment().format('YYYYMMDD')+'T'+moment().format('HH:mm:ssZZ')
    // }
  
    const options = {
      headers: Object.assign({}, {}, headers),
      method: method
    }
    if (token !== null)
      options.headers['Authorization'] = `${token}`
    if (method !== 'get')
      options['body'] = options.headers['Content-Type'] === 'application/json' ? JSON.stringify(body) : body

    if(headers['Content-Type'] === 'multipart/form-data')
      delete options.headers['Content-Type']

    const requestUrl = isApi ? process.env.REACT_APP_API_URL + url: url

    return fetch(requestUrl, options)
      .then(result => result.json())
      .then(json => {
        if (json.status >= 200 && json.status < 300) {
          return resolve(json.status === 204 ? {} : json)
        }
        return reject(json)
      })
      .catch(error => reject(error))

  })

}